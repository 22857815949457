import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useAuthContext } from "../../../auth/useAuthContext"
import useRole from "../../../hooks/api/useRole"
import useUser from "../../../hooks/api/useUser"
import useForm from "../../../hooks/useForm"
import useModal from "../../../hooks/useModal"
import { catalogs } from "../../../utils/catalogs"

const useUserAccountPage = ({profile}) => {

    const {user, refreshUser} = useAuthContext()
    const {iduser} = useParams()
    const { enqueueSnackbar } = useSnackbar();
    const formManager = useForm(form_data)
    const passwordController = useForm(password_formdata)
    const userManager = useUser()
    const roleManager = useRole()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [currentTab, setCurrentTab] = useState('general');
    const [isdoctor, setIsDoctor] = useState(false)

    const [account, setAccount] = useState()
    const [file, setFile] = useState()
    const [roles, setRoles] = useState([])
    const [user_roles, setUserRoles] = useState([])

    const modalManager = useModal({
        add_role:false,
        edit_role:false
    })

    
    const {form, onValidateForm, onGetFormData, onInitForm, onUpdateDBErrors} = formManager

    useEffect(() => {
        actions.onInitModule()
    },[iduser])

    


    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                //console.log(`Request to ${iduser}`)
                let _user 
                if(!profile){
                    _user = await userManager.actions.onGet(iduser)
                }else{
                    console.log('Es mi perfil')
                    _user = user
                }
                const _roles = await roleManager.actions.onGetAll()
                const _userroles = await roleManager.actions.onGetAllByUser(profile ? user?.id_user : iduser)

                let _isdoctor = false
                //console.log(_userroles)
                _userroles?.data.forEach(item => {
                    if(item.role?.toLowerCase() === 'doctor'){
                        _isdoctor = true
                    }
                })

                setIsDoctor(_isdoctor)
                
                setRoles(_roles.data)
                setUserRoles(_userroles.data)
                setAccount(_user)
                onInitForm(_user)
                passwordController.onResetForm()
            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setLoading(false)
        },
        onReset: () => passwordController.onResetForm(),
        onRefresh: async() => {
            try {
                let _user 
                if(!profile){
                    _user = await userManager.actions.onGet(iduser)
                }else{
                    _user = await userManager.actions.onGet(user?.id_user)
                }
                setAccount(_user)
                onInitForm(_user)
                if(`${user.id_user}` === `${iduser}` || profile) await refreshUser()

            } catch (error) {
                
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
        },
        onRefreshUserRoles: async() => {
            try {
                const _userroles = await roleManager.actions.onGetAllByUser(iduser)
                setUserRoles(_userroles.data)
                setError(error)
                enqueueSnackbar("Actualización realizada exitosamente", {variant:"success"})
            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
        },
        onAvatarDrop: (acceptedFiles) => {
            if(acceptedFiles.length){
                const _file = acceptedFiles[0]
                const newFile = Object.assign(_file, {
                    preview: URL.createObjectURL(_file),
                });
                //console.log(newFile)
                if(_file){
                    setFile(newFile)
                }
                //setFile(_data[0])
            }
        },
        onInputChange: (_input) => {
            formManager.onChangeForm(_input)
        },
        onPasswordInputChange: (_input) => {
            passwordController.onChangeForm(_input)
        },
        onChangeSection: (value) => {
            setCurrentTab(value)
        },
        onSubmit: async() => {
            const valid = onValidateForm(form)
            const iduser = account?.id_user
            if(!valid){
                setError({message:'Debe llenar todos los campos solicitados'})
                return
            }
    
            let data2send = onGetFormData({origin: account, eraseEmptySpaces:true})
            setSending(true)

            

            try {
                await userManager.actions.onEdit(iduser, data2send)
                if(file) await userManager.actions.onUpdatePicture(iduser, file)

                await actions.onRefresh()
                enqueueSnackbar('Información actualizada exitosamente', {variant:"success"})

            } catch (error) {
                console.log(error)
                if(error.details) onUpdateDBErrors(error.details)
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
                setSending(false)
            }
            setSending(false)
        },
        onUpdatePassword: async() => {
            const valid = onValidateForm(passwordController.form)
            
            if(!valid){
                enqueueSnackbar('Debe llenar todos los campos solicitados', {variant:"error"})
                return
            }
    
            let data2send = passwordController.onGetFormData()

            if(data2send.password !== data2send.passwordConfirmation){
                enqueueSnackbar('Las contraseñas no coinciden', {variant:"error"})
                return
            }
            if(data2send.new_password !== data2send.passwordConfirmationProfile){
                enqueueSnackbar('Las contraseñas no coinciden', {variant:"error"})
                return
            }
    
            setSending(true)

            

            try {
                if(profile){
                    await userManager.actions.onUpdatePassword(data2send)
                }else{
                    await userManager.actions.onEdit(iduser, data2send)
                }
                if(file) await userManager.actions.onUpdatePicture(iduser, file)

                await actions.onRefresh()
                await passwordController.onResetForm()
                enqueueSnackbar('Contraseña actualizada exitosamente', {variant:"success"})

            } catch (error) {
                console.log(error)
                if(error?.details) onUpdateDBErrors(error.details)
                setError(error)
                setSending(false)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setSending(false)
        },
        onUpdateRole: async(_role, data2send) => {
            try {
                await roleManager.actions.onEdit2User(_role?.user_id, _role?.id_user_role, data2send)
                await actions.onRefreshUserRoles()
            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
        }
    }


    const system = {loading, sending, error}
    const data = {currentTab, file, account, roles, user_roles, isdoctor}
    const passwordForm = passwordController.form

    return {
        system,
        actions, 
        data,
        form,
        passwordForm,
        modalManager
    };
}
 
export default useUserAccountPage;

const password_formdata = {
    previous_password: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'previous_password',
            type: 'password',
            fullWidth: true,
            label:'Contraseña previa',
            autoComplete:"new-password",
            placeholder:'Ingresa tu contraseña previa',
            helperText:'Debe contener al menos 6 caracteres'
        },
        rules: {type: 'distance', min:6}
    },
    
    new_password: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        injectData:'passwordConfirmationProfile',
        config: {
            id: 'new_password',
            type: 'password',
            fullWidth: true,
            label:'Nueva contraseña',
            autoComplete:"new-password",
            placeholder:'Ingresa tu contraseña',
            helperText:'Debe contener al menos 6 caracteres'
        },
        rules: {type: 'distance', min:6}
    },
    password: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        injectData:'passwordConfirmation',
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label:'Contraseña',
            autoComplete:"new-password",
            placeholder:'Ingresa tu contraseña',
            helperText:'Debe contener al menos 6 caracteres'
        },
        rules: {type: 'distance', min:6}
    },
    passwordConfirmationProfile: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        compareTarget:'new_password',
        config: {
            id: 'passwordConfirmationProfile',
            type: 'password',
            fullWidth: true,
            label:'Confirmar contraseña',
            placeholder:'Confirmar contraseña',
            helperText:'Las contraseñas no coinciden'
        },
        rules: {type: 'equals'}
    },
    passwordConfirmation: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        compareTarget:'password',
        config: {
            id: 'passwordConfirmation',
            type: 'password',
            fullWidth: true,
            label:'Confirmar contraseña',
            placeholder:'Confirmar contraseña',
            helperText:'Las contraseñas no coinciden'
        },
        rules: {type: 'equals'}
    },
}

const form_data = {
    first_name: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Nombre(s)',
            placeholder:'Nombre(s)',
            helperText:'Debe ser un valor entre 1 a 255 caracteres'
        },
        rules: {type: 'distance', min:1, max:255}
    },
    fathers_last_name: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'fathers_last_name',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Apellido paterno',
            placeholder:'Apellido paterno',
            helperText:'Debe ser un valor entre 1 a 255 caracteres'
        },
        rules: {type: 'distance', min:1, max:255}
    },
    mothers_last_name: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'mothers_last_name',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Apellido materno',
            placeholder:'Apellido materno',
            helperText:'Debe ser máximo de 255 caracteres'
        },
        rules: {type: 'distance', min:0, max:255}
    },
    email: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label:'Email',
            autoComplete:"new-password",
            placeholder:'Ingresa tu correo electrónico',
            helperText:'Correo no válido'
        },
        rules: {type: 'email'}
    },
    user_gender_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options:catalogs.genders,
        config: {
            id: 'user_gender_id',
            type: 'select',
            fullWidth: true,
            label:'Género',
            autoComplete:"new-password",
            placeholder:'Selecciona tu género',
            helperText:'Género no válido, seleccione una opción'
        },
        rules: {type: 'select'}
    },
    mobile_country_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:catalogs.mobile_country_codes,
        config: {
            id: 'mobile_country_id',
            type: 'select',
            fullWidth: true,
            label:'Cod. País',
            autoComplete:"new-password",
            placeholder:'+52',
            helperText:''
        },
        rules: {type: 'select'}
    },
    mobile: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'mobile',
            type: 'tel',
            fullWidth: true,
            label:'Celular',
            autoComplete:"new-password",
            placeholder:'5511223344',
            helperText:'Número móvil no válido'
        },
        rules: {type: 'phone_number'}
    },
    details: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'details',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            multiline:true,
            rows:6,
            label:'Detalles',
            placeholder:'Escriba un poco más sobre este usuario',
            helperText:'Debe ser un valor entre 1 a 512 caracteres'
        },
        rules: {type: 'distance', min:0, max:512}
    },
}
